import * as L from 'leaflet'
import AED from '../../interfaces/aed'
import rank_icon from '../../icons/rank_icon'

const set_string_field = (base: string, value?: string): void => {
  const field_dom = `${base}_field`
  const field = document.getElementById(field_dom)
  if (field) {
    if (value) {
      if (document.getElementById(base)) {
        document.getElementById(base).innerHTML = value
      }
      field.style.display = ''
    } else {
      field.style.display = 'none'
    }
  }
}

const set_number_field = (base: string, value?: number): void => {
  const field_dom = `${base}_field`
  const field = document.getElementById(field_dom)
  if (field) {
    if (value) {
      if (document.getElementById(base)) {
        document.getElementById(base).innerHTML = value.toString()
      }
      field.style.display = ''
    } else {
      field.style.display = 'none'
    }
  }
}

const aed_merker_event = (marker: L.Marker, aed: AED): void => {
  marker.on('click', (e) => {
    set_string_field('install_location_name', aed.install_location_name)
    set_number_field('register_number', aed.register_number)
    set_string_field('install_address_detail', aed.install_address_detail)
    set_string_field('install_prefecture_name', aed.install_prefecture_name)
    set_string_field('install_address', aed.install_address)
    set_string_field('install_type_name', aed.install_type_name)
    set_string_field('conditions_for_everyone', aed.conditions_for_everyone)
    set_string_field('note', aed.note)
    set_string_field('checker', aed.checker)
    set_string_field('install_date', aed.install_date)
    // rank
    const rank_html = `精度${aed.rank} <img src='${rank_icon(aed.rank)}'>`
    if (document.getElementById('rank')) {
      document.getElementById('rank').innerHTML = rank_html
    }
    // others
    const available_time_field = document.getElementById("available_time_field")
    if (available_time_field) {
      available_time_field.style.display = 'none'
    }
    const open_days_field = document.getElementById("open_days_field")
    if (open_days_field) {
      open_days_field.style.display = 'none'
    }
    const utility_time_note_field = document.getElementById("utility_time_note_field")
    if (utility_time_note_field) {
      utility_time_note_field.style.display = 'none'
    }
    if (aed.utility_time_type) {
      const available_time_message = aed.available_time
      if (document.getElementById('available_time')) {
        document.getElementById('available_time').innerHTML = available_time_message
      }
      if (available_time_field) {
        available_time_field.style.display = ''
      }
      if (!aed.use_everyday) {
        if (document.getElementById('open_days')) {
          document.getElementById('open_days').innerHTML = aed.open_days
        }
        if (open_days_field) {
          open_days_field.style.display = ''
        }
      }
      if (aed.utility_time_note) {
        const utility_time_note = document.getElementById('utility_time_note')
        if (utility_time_note) {
          utility_time_note.innerHTML = aed.utility_time_note
        }
        if (utility_time_note_field) {
          utility_time_note_field.style.display = ''
        }
      }
    }
  })
}

export default aed_merker_event

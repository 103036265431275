import AED from '../interfaces/aed'

interface APIResponse {
  readonly aeds: [AED];
}

const search_by_location = async (latitude: number, longitude: number): Promise<[AED]> => {
  const response = await fetch(`/api/aed/search_by_location?latitude=${latitude}&longitude=${longitude}`, {
    method: 'GET',
    mode: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const json:APIResponse = await response.json()
  const aeds = json.aeds
  return aeds
}

export default search_by_location
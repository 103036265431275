import * as L from 'leaflet'

const create_marker = (longitude: number, latitude: number, icon?: L.Icon, draggable?: boolean): L.Marker => {
  let _draggable = false
  if (draggable) {
    _draggable = draggable
  }
  if (icon) {
    return L.marker([latitude, longitude], { draggable: _draggable, icon: icon })
  }
  return L.marker([latitude, longitude], { draggable: _draggable })
}

export default create_marker
import * as L from 'leaflet'
import {icon} from '@fortawesome/fontawesome-svg-core'
import {faExpandAlt, faCompressArrowsAlt} from '@fortawesome/free-solid-svg-icons'

const faExpandAltIcon = icon(faExpandAlt)
const faCompressArrowsAltIcon = icon(faCompressArrowsAlt)

class SwitchClusterControl extends L.Control {
  status = false
  marker_layer = null
  clustor_group = null
  constructor(marker_layer: L.LayerGroup, cluster_group: L.MarkerClusterGroup) {
    super({position: 'topleft'})
    this.marker_layer = marker_layer
    this.clustor_group = cluster_group
  }
  onAdd(map: L.Map): HTMLElement {
    const container = L.DomUtil.create('div', 'switch-cluster-control leaflet-bar')
    const link = L.DomUtil.create('a', 'switch-cluster-toggle', container)
    link.appendChild(faExpandAltIcon.node[0])
    const stop = L.DomEvent.stopPropagation
    const _updateButton = () => {
      if (this.status) {
        const svg = link.getElementsByTagName('svg')
        link.removeChild(svg[0])
        link.appendChild(faCompressArrowsAltIcon.node[0])
        link.title = "ピンを展開します"
      } else {
        const svg = link.getElementsByTagName('svg')
        link.removeChild(svg[0])
        link.appendChild(faExpandAltIcon.node[0])
        link.title = "ピンをまとめます"
      }
    }
    const _toggle = () => {
      if (!this.status) {
        map.removeLayer(this.marker_layer)
        map.addLayer(this.clustor_group)
      } else {
        map.removeLayer(this.clustor_group)
        map.addLayer(this.marker_layer)
      }
      this.status = !this.status
      _updateButton()
    }
    L.DomEvent
      .on(link, 'click', stop)
      .on(link, 'mousedown', stop)
      .on(link, 'dblclick', stop)
      .on(link, 'click', L.DomEvent.preventDefault)
      .on(link, 'click', _toggle, this)
    return container
  }  
}

export default SwitchClusterControl
import * as screenfull from 'screenfull'
window.screenfull = screenfull
import * as L from 'leaflet'
//import './stylesheets/leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.fullscreen'
import 'leaflet.fullscreen/Control.FullScreen.css'
import SwitchClusterControl from './maps/controls/switch_cluster_control'
import prepare_map from './maps/prepare_map'
import search_by_location from './apis/search_by_location'
import precision_icons from './maps/icons/precision_icons'
import create_marker from './maps/markers/create_marker'
import aed_content_popup from './maps/popups/aed_contents_popup'
import aed_marker_event from './maps/events/aed_marker_event'

const map = L.map('aed_map', {'tap': false})
prepare_map(map)
const layer = L.layerGroup()
layer.addTo(map)
const markerClusterGroup = L.markerClusterGroup({showCoverageOnHover: false, maxClusterRadius: 10})
const fullscreenEnabled =
  document.fullscreenEnabled ||
  document.mozFullScreenEnabled ||
  document.documentElement.webkitRequestFullScreen
if (fullscreenEnabled) {
  L.control.fullscreen({
    position: 'topleft',
    title: 'フルスクリーン表示',
    titleCancel: 'フルスクリーンを閉じる',
    forceSeparateButton: true,
    forcePseudoFullscreen: true,
    fullscreenElement: false
  }).addTo(map)
  map.on('enterFullscreen', () => {
    console.log('enter')
    document.body.style.overflow = 'hidden'
    document.getElementById('banner-overlay').style.display = 'block'
  })
  map.on('exitFullscreen', () => {
    document.body.style.overflow = 'auto'
    document.getElementById('banner-overlay').style.display = 'none'
  })
}
map.addControl(new SwitchClusterControl(layer, markerClusterGroup))
const aed_map = document.getElementById('aed_map')
const id_html = document.getElementById('id') as HTMLInputElement
let aed_id = 0
if (id_html) {
  aed_id = parseInt(id_html.dataset.value)
}
if (aed_map.getAttribute('data-realtime') == 'true') {
  const aeds: Array<number> = []
  const show_current_position_aeds = () => {
    const latlng = map.getCenter()
    search_by_location(latlng.lat, latlng.lng)
      .then((_aeds) => {
        _aeds.forEach(aed => {
          if (!(aeds.includes(aed.id))) {
            aeds.push(aed.id)
            const location = aed.location
            const marker = create_marker(location.longitude, location.latitude, precision_icons[aed.rank])
            aed_content_popup(marker, aed)
            aed_marker_event(marker, aed)
            layer.addLayer(marker)
            markerClusterGroup.addLayer(marker)
            if (aed_id === aed.id) {
              marker.fire('click')
            }
          }
        })
      })
  }
  map.on('moveend', () => {
    show_current_position_aeds()
  })

  const move_to_browser_position:PositionCallback = (success: GeolocationPosition):void => {
    const move_to = L.latLng(success.coords.latitude, success.coords.longitude)
    map.setView(move_to, 18, {animate: true})
  }
  if (navigator.geolocation && aed_map.getAttribute('data-geolocation') == 'true') {
    navigator.geolocation.getCurrentPosition(move_to_browser_position)
  }
  show_current_position_aeds()
}

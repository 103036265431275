import * as L from 'leaflet'
import rank_icon from '../../icons/rank_icon'

const precision_icons: {[name: string]: L.Icon} = {}
void ['A', 'B', 'C', 'D'].forEach(precision => {
  precision_icons[precision] = L.icon({
    iconUrl: rank_icon(precision),
    iconSize: [31, 41],
    iconAnchor: [15, 40],
    popupAnchor: [0, -32],
  })
})

export default precision_icons
import * as L from 'leaflet'
import AED from '../../interfaces/aed'

const popup_contents = (aed: AED): string => {
  const title = "<b>" + (aed.install_location_name || "") + "</b>"
  let body = ""
  if (aed.install_address_detail) {
    body += "<br>" + aed.install_address_detail
  }
  if (aed.everyone_allow) {
    if (aed.everyone_allow === "認めない") {
      body += "<br> <span style='border-bottom:solid; border-width:1px;'>外部の使用を" + aed.everyone_allow + "</span>"
    } else {
      body += "<br> 外部の使用を" + aed.everyone_allow
    }
  }
  if (aed.conditions_for_everyone) {
    body += " <br> 条件: " + aed.conditions_for_everyone
  }
  return title + body
}

const aed_content_popup = (marker: L.Marker, aed: AED): void => {
  const content = popup_contents(aed)
  marker.bindPopup(content).openPopup()
}

export default aed_content_popup
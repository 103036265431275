import * as L from 'leaflet'
import mapion_layer from './layers/mapion'
import gsi_std_layer from './layers/gsi_std'
import gsi_pole_layer from './layers/gsi_pole'

const prepare_map = (map: L.Map): void => {
  mapion_layer.addTo(map)
  const longitude_html = document.getElementById('longitude') as HTMLInputElement
  const latitude_html = document.getElementById('latitude') as HTMLInputElement
  const zoom_html = document.getElementById('zoom') as HTMLInputElement
  const longitude = longitude_html ? parseFloat(longitude_html.dataset.value) : 139.766865
  const latitude = latitude_html? parseFloat(latitude_html.dataset.value) : 35.681109
  const zoom = zoom_html ? parseInt(zoom_html.dataset.value) : 10
  map.setView([latitude, longitude], zoom)
  const base_layers = {
    "マピオン": mapion_layer,
    "地理院地図: 標準地図": gsi_std_layer,
    "地理院地図: 淡色地図": gsi_pole_layer,
  }
  L.control.layers(base_layers).addTo(map)
  L.control.scale({imperial:false}).addTo(map)
}

export default prepare_map